<template lang="pug">
  v-container(fluid="")
    // Snacbar
    v-snackbar(v-model='snackbar.show' :color='snackbar.color' right top :timeout="4000")
      | {{snackbar.message}}
      v-btn(dark='' text='' @click='snackbar.show = false')
        | Close
    Explore()
    // Watch
    v-dialog(:fullscreen='isMobile' persistent :value='watchDialog'  max-width='600px'   transition="dialog-bottom-transition")
      div(style ='position:relative; height: 100%;')
        watch-videos(:model ='video' v-if="watchDialog" @close="watchDialog=false")
        div(style='position:absolute; top:0; width:100%;')
          v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='updateItemDialog=false')
            v-icon(size="30") mdi-close-box
    template(v-if="downloads")
      v-layout().video-card__container
        v-row
          v-toolbar(color="secondary")
            v-layout(style='display: flex; flex-direction: row;')
              .toolbar-header.mr-auto
                p.white--text The following are your previously downloaded content.
        v-row
          v-card.video-card.mx-auto.my-10.my-videos(v-for="(download, i) in downloads" max-width='330px' style="margin: 0 auto")
            .video-card__img-wrapper
              v-img(:src='download.thumbnail' blur="100px" height="180px" width="100%" style="clip-path: inset(1rem);" @click="watch(download)")
            v-layout
              v-flex.text-center
                h2 {{download.title}}
            v-layout
              v-flex.text-center
                span.primary--text {{download.type}}
            v-layout
              v-flex.d-flex.align-center.justify-center
                img(src="@/assets/images/location-icon.png")
                span.ml-2 {{download.location.locality}}, {{download.location.state}}
            v-layout
              v-layout.d-flex.align-center.justify-center(col="6")
                img(src="@/assets/images/calendar-icon.png")
                p.ml-2 {{download.date | moment}}
              v-layout.d-flex.align-center.justify-center(col="6")
                img(src="@/assets/images/time-icon.png")
                p.ml-2 {{download.time}}
            v-layout
              v-layout(col)
                v-flex.d-flex.align-center.justify-center
                  p.mb-0.my-videos__price {{download.price | moneyFormat}}
              v-layout(col)
                v-flex.d-flex.align-center.justify-center
                  v-btn.text-uppercase.mx-auto.my-videos__play(color="primary" @click="watch(download)") play
              v-layout(col)
                v-flex.d-flex.align-center.justify-center
                  v-btn(icon="" color="secondary" @click="discharging(download)")
                    v-icon mdi-download
    template(v-else)
      v-row
        v-col(cols="12")
          v-card-title.justify-center.font-weight-light
            | You haven't downloaded content yet
          v-card-text.body-1.text-center.font-weight-light.grey--text
            | The content you will download appear here.
        v-col(cols="12").text-center
          v-btn.mr-0(color="secondary" medium @click='goSearch')
            | Download content
</template>

<script>
  import loaders from '@/plugins/mixins/loaders'
  import axios from 'axios'
  import Explore from '@/components/custom/Explore'
  import moment from 'moment'
  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
  })
  export default {
    components: {
      Explore,
      WatchVideos: () => import('@/components/custom/dialogs/Videos/WatchDialog.vue'),
    },

    filters: {
      moneyFormat: function (value) {
        return formatter.format(value)
      },
      moment: function (date) {
        return moment(date).format('MM-DD-YYYY')
      },
    },

    mixins: [loaders],

    data () {
      return {
        video: '',
        watchDialog: false,
        snackbar: {
          show: false,
          color: 'red',
          message: '',
        },
      }
    },

    computed: {
      isMobile () {
        return window.innerWidth < 1024
      },
      downloads () {
        return this.$store.getters['downloads/getDownloads']
      },
    },

    created () {
      const vm = this
      vm.loadDownloads()
    },

    methods: {
      watch (element) {
        const vm = this
        vm.video = element
        vm.watchDialog = true
      },
      goSearch () {
        this.$router.replace('/search')
      },
      discharging (element) {
        // console.log(element)
        const vm = this
        axios({
          url: element.thumbnail,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          // console.log(response)
          const type = response.data.type.split('/')
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')
          // console.log(type)
          fileLink.href = fileURL
          fileLink.setAttribute('download', element.title.concat('.', type[1]))
          document.body.appendChild(fileLink)
          fileLink.click()

          vm.snackbar = {
            show: true,
            color: 'success',
            message: 'Download successful',
          }
        }).catch(error => {
          console.log(error.response)
          vm.snackbar = {
            show: true,
            color: 'red',
            message: 'Try again',
          }
        })
      },
    },
  }
</script>

<style lang="scss">

.my-videos {
  &__play {
    font-size: 18px !important;
  }
  &__price {
    font-weight: bold;
    font-size: 22px;
    color: var(--secondary-color);
  }
  &__show-more {
    color: var(--secondary-color) !important;
    font-size: 15px;
  }
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
